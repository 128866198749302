
import { defineComponent, onMounted, ref, reactive, Ref, inject } from "vue";
import { useI18n } from "vue-i18n";
import { useShowErrors } from "@hd2/common/src/composable/useShowErrors";
import { TableState } from "ant-design-vue/lib/table/interface";
import {
  Billing,
  VisitTypeExt,
  BillingObject,
  BillingTable,
  BillingTableRecord,
  BillingFilterModel,
} from "../../types";
import { deepIterate } from "@hd2/common/src/utils";
import { get } from "lodash";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons-vue";
import { visitTypesIconMap } from "../utils/const";
import moment from "moment";
import BillingFilters from "../components/BillingFilters.vue";
import { AxiosStatic } from "axios";

interface Table {
  columns: TableState["columns"];
  loading: boolean;
}

interface Summary {
  [key: string]: number;
}

export const BillingComponent = defineComponent({
  components: {
    CaretDownOutlined,
    CaretUpOutlined,
    BillingFilters,
  },
  setup() {
    const { t } = useI18n();
    const http = inject("http") as AxiosStatic;
    const { showErrors } = useShowErrors();
    const billingObject: Ref<BillingObject> = ref({});
    const summaryArray: Ref<Summary> = ref({});

    const filter: BillingFilterModel = reactive({
      date: [moment.utc().startOf("month"), moment().utc().endOf("month")],
    });

    const tableBilling: Table = reactive({
      columns: [
        {
          title: t("BILLING.TABLE.HEADER.TYPE"),
          dataIndex: "visitType",
          sorter: false,
          slots: { customRender: "billingType" },
          width: window.screen.width < 480 ? 350 : "50%",
        },
        {
          title: t("BILLING.TABLE.HEADER.AMOUNT"),
          dataIndex: "visitAmount",
          sorter: false,
          slots: { customRender: "amount" },
          width: window.screen.width < 480 ? 100 : "50%",
        },
      ],
      loading: true,
    });

    const getBilling = async (dates: moment.Moment[]) => {
      tableBilling.loading = true;
      const link = `v1/doctors/settlement?from=${moment(dates[0]).format(
        "YYYY-MM-DD"
      )}&to=${moment(dates[1]).format("YYYY-MM-DD")}`;
      try {
        const visitsRes = await http.get(link).then((res): Billing => res.data);
        let type = "";
        let table = "";
        let isFirstElement = true;
        let index = 0;
        let amount = 0;
        let summaryAmout = 0;
        billingObject.value = {};
        summaryArray.value = {};
        deepIterate<Billing>(visitsRes, (keys) => {
          if (!billingObject.value[keys[0]]) billingObject.value[keys[0]] = [];
          const billingTableRecord: BillingTableRecord = {
            billingType: keys[2] as VisitTypeExt,
            visitAmount: get(visitsRes, keys),
          };

          if (table != keys[0] && table != "") {
            summaryAmout = summaryAmout + amount;
            summaryArray.value[table] = summaryAmout;
            index = 0;
            isFirstElement = true;
            summaryAmout = 0;
          }
          table = keys[0];
          if (isFirstElement == true) {
            const arr: BillingTable = {
              billingType: keys[1] as VisitTypeExt,
              visitAmount: 0,
              children: [],
            };
            arr.children.push(billingTableRecord);
            billingObject.value[keys[0]].push(arr);
            amount = get(visitsRes, keys);
            isFirstElement = false;
          } else if (type != keys[1]) {
            const arr: BillingTable = {
              billingType: keys[1] as VisitTypeExt,
              visitAmount: 0,
              children: [],
            };
            arr.children.push(billingTableRecord);
            billingObject.value[keys[0]].push(arr);
            index = index + 1;
            summaryAmout = summaryAmout + amount;
            amount = get(visitsRes, keys);
          } else {
            billingObject.value[keys[0]][index].children.push(
              billingTableRecord
            );
            amount = amount + get(visitsRes, keys);
          }
          billingObject.value[keys[0]][index].visitAmount = amount;
          type = keys[1];
        });
        summaryAmout = summaryAmout + amount;
        summaryArray.value[table] = summaryAmout;
      } catch (err: any) {
        showErrors(err.response?.data);
      } finally {
        tableBilling.loading = false;
      }
    };
    const onFilterChange = (dates: moment.Moment[]) => {
      getBilling(dates);
    };

    onMounted(() => {
      getBilling(filter.date);
    });

    return {
      CaretDownOutlined,
      CaretUpOutlined,
      billingObject,
      visitTypesIconMap,
      tableBilling,
      summaryArray,
      filter,
      onFilterChange,
      t,
    };
  },
});
export default BillingComponent;
