import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d900789e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "billing" }
const _hoisted_2 = { class: "billing__table-spinner" }
const _hoisted_3 = { class: "table__header" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "table__billing-type" }
const _hoisted_7 = {
  key: 0,
  class: "table__billing-type-icon-wrapper"
}
const _hoisted_8 = { class: "table__billing-type-icon" }
const _hoisted_9 = { class: "anticon" }
const _hoisted_10 = { class: "table__visit-amout" }
const _hoisted_11 = { class: "table__summary" }
const _hoisted_12 = { class: "summary__row" }
const _hoisted_13 = { class: "summary__col" }
const _hoisted_14 = { class: "summary__col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_billing_filters = _resolveComponent("billing-filters")!
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_CaretDownOutlined = _resolveComponent("CaretDownOutlined")!
  const _component_CaretUpOutlined = _resolveComponent("CaretUpOutlined")!
  const _component_a_table = _resolveComponent("a-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, _toDisplayString(_ctx.t("BILLING.HEADER")), 1 /* TEXT */),
    _createVNode(_component_billing_filters, {
      onChange: _ctx.onFilterChange,
      value: _ctx.filter.date,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filter.date) = $event))
    }, null, 8 /* PROPS */, ["onChange", "value"]),
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      _createVNode(_component_a_spin)
    ], 512 /* NEED_PATCH */), [
      [_vShow, _ctx.tableBilling.loading]
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.billingObject, (item, key) => {
      return _withDirectives((_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["billing__table", ['billing__table', 'billing__table-' + key]]),
        key: key
      }, [
        _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.t(
            "BILLING.TABLE.TYPE." +
              key
                .split(/(?=[A-Z])/)
                .join("_")
                .toUpperCase()
          )), 1 /* TEXT */),
        _createVNode(_component_a_table, {
          columns: _ctx.tableBilling.columns,
          class: "billing-table",
          "row-key": (record) => record.billingType,
          "data-source": item,
          pagination: false
        }, {
          expandIcon: _withCtx((props) => [
            (props.record?.children?.length > 0)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  (props.expanded == false)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                        _createVNode(_component_CaretDownOutlined, {
                          onClick: ($event: any) => (props.onExpand(props.record, _ctx.e))
                        }, null, 8 /* PROPS */, ["onClick"])
                      ]))
                    : (_openBlock(), _createElementBlock("span", _hoisted_5, [
                        _createVNode(_component_CaretUpOutlined, {
                          onClick: ($event: any) => (props.onExpand(props.record, _ctx.e))
                        }, null, 8 /* PROPS */, ["onClick"])
                      ]))
                ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
              : _createCommentVNode("v-if", true)
          ]),
          billingType: _withCtx(({ record }) => [
            _createElementVNode("div", _hoisted_6, [
              (record.children?.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("span", _hoisted_9, [
                        (_openBlock(), _createBlock(_resolveDynamicComponent(
                      _ctx.visitTypesIconMap.get(record.billingType?.split('_')[0])
                    )))
                      ])
                    ])
                  ]))
                : _createCommentVNode("v-if", true),
              _createElementVNode("div", {
                class: _normalizeClass([
                'table__billing-type-title',
                record.children ? '' : 'table__billing-type-title-space',
              ])
              }, _toDisplayString(record.children
                  ? _ctx.t("BILLING.VISIT_TYPE." + record.billingType)
                  : _ctx.t("BILLING.BILLING_TYPE." + record.billingType)), 3 /* TEXT, CLASS */)
            ])
          ]),
          amount: _withCtx(({ record }) => [
            _createElementVNode("div", _hoisted_10, _toDisplayString(record.visitAmount), 1 /* TEXT */)
          ]),
          footer: _withCtx(() => [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.t("BILLING.TABLE.FOOTER.SUMMARY")), 1 /* TEXT */),
                _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.summaryArray[key]), 1 /* TEXT */)
              ])
            ])
          ]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["columns", "row-key", "data-source"])
      ], 2 /* CLASS */)), [
        [_vShow, !_ctx.tableBilling.loading]
      ])
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}