import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_row = _resolveComponent("a-row")!
  const _component_CalendarOutlined = _resolveComponent("CalendarOutlined")!
  const _component_a_range_picker = _resolveComponent("a-range-picker")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createBlock(_component_a_card, { class: "billing-filters" }, {
    default: _withCtx(() => [
      _createVNode(_component_a_row, { class: "billing-filters__header" }, {
        default: _withCtx(() => [
          _createElementVNode("h2", null, _toDisplayString(_ctx.t("BILLING.FILTERS.HEADER")), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_a_row, {
        gutter: [0, 16],
        justify: "space-between"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_col, {
            md: 7,
            xs: 24
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_range_picker, {
                format: "DD.MM.YYYY",
                style: {"width":"100%"},
                onChange: _ctx.onChange,
                ranges: _ctx.predefinedDateRanges,
                "allow-clear": false,
                "input-read-only": true,
                showToday: false,
                value: _ctx.model.date,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.date) = $event))
              }, {
                suffixIcon: _withCtx(() => [
                  _createVNode(_component_CalendarOutlined)
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["onChange", "ranges", "value"])
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}