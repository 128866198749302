
import { defineComponent, reactive, PropType, watch } from "vue";
import { useI18n } from "vue-i18n";
import { BillingFilterModel } from "../../types";
import moment, { Moment } from "moment";
import { CalendarOutlined } from "@ant-design/icons-vue";

export const BillingFiltersComponent = defineComponent({
  emits: ["change", "update:value"],
  components: {
    CalendarOutlined,
  },
  props: {
    value: {
      type: Array as PropType<Moment[]>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const predefinedDateRanges = {
      [t("BILLING.FILTERS.TODAY")]: [moment(), moment()],
      [t("BILLING.FILTERS.THIS_WEEK")]: [
        moment().startOf("week"),
        moment().endOf("week"),
      ],
      [t("BILLING.FILTERS.THIS_MONTH")]: [
        moment().startOf("month"),
        moment().endOf("month"),
      ],
      [t("BILLING.FILTERS.PREVIOUS_MONTH")]: [
        moment().add(-1, "month").startOf("month"),
        moment().add(-1, "month").endOf("month"),
      ],
      [t("BILLING.FILTERS.LAST_30_DAYS")]: [
        moment().add(-30, "days"),
        moment(),
      ],
    };

    const model: BillingFilterModel = reactive({
      date: props.value,
    });

    watch(
      () => props.value,
      (nv) => {
        model.date = nv;
      }
    );

    const onChange = (dates: moment.Moment[]) => {
      emit("change", dates);
      emit("update:value", dates);
    };

    return {
      t,
      model,
      predefinedDateRanges,
      onChange,
      moment,
    };
  },
});

export default BillingFiltersComponent;
